import React, { useState } from "react";
import { Form, FormField, Grid, Icon } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";

const AdventurePersonInfoTab = ({
    person, 
    onSaveField, 
    disabled
}) => {
    const [appearanceTraits, setAppearanceTraits] = useState(person.appearanceTraits || '');
    const [shortDescription, setShortDescription] = useState(person.shortDescription || '');
    const [description, setDescription] = useState(person.description || '');

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <div className="person-first-row">
                            <div className="person-icon">
                                <Icon name="user circle" size="massive" />
                            </div>
                            <div className="person-other-info">
                                <FormField>
                                    <label>Черты внешности</label>
                                    <input
                                        disabled={disabled}
                                        value={appearanceTraits}
                                        onChange={(e) => setAppearanceTraits(e.target.value)}
                                        onBlur={() => onSaveField('appearanceTraits', appearanceTraits)}
                                    />
                                </FormField>
                                <FormField>
                                    <label>Краткое описание</label>
                                    <textarea
                                        rows={2}
                                        disabled={disabled}
                                        value={shortDescription}
                                        onChange={(e) => setShortDescription(e.target.value)}
                                        onBlur={() => onSaveField('shortDescription', shortDescription)}
                                    />
                                </FormField>
                            </div>
                        </div>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <MarkdownEditor
                        disabled={disabled}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => onSaveField('description', description)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AdventurePersonInfoTab;