import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import ImageUploader from "../../../controls/imageUploader";

const CreatePlaceDialog = ({
    isOpen,
    disabled,
    title,
    parentId,
    parentX,
    parentY,
    onCancel,
    onSave
}) => {
    const [name, setName] = useState('');
    const [file, setFile] = useState(null);

    useEffect(() => {
        setName('');
        setFile(null);
    }, [isOpen]);

    const saveData = () => {
        const form = new FormData();
        form.append('name', name);
        parentId && form.append('parentPlaceId', parentId);
        parentX && form.append('parentPlaceX', parentX);
        parentY && form.append('parentPlaceY', parentY);
        form.append('file', file, file ? file.name : 'file');
        onSave(form);
    };

    return (
        <Modal size="tiny" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Название</label>
                        <input
                            value={name}
                            disabled={disabled}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Изображение</label>
                        <ImageUploader 
                            disabled={disabled}
                            selectedFile={file}
                            onSelectFile={setFile}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={onCancel}
                >
                    Отмена
                </Button>
                <Button 
                    positive 
                    disabled={disabled}
                    onClick={saveData}
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreatePlaceDialog;