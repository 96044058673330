import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';

import {
    LOGIN_LINK,
    DASHBOARD_LINK,
    CHARACTER_FORM_LINK,
    CHARACTER_NEW_LINK,
    GROUPS_FORM_LINK,
    GROUPS_LIST_LINK,
    PROFILE_LINK,
    REGISTER_LINK,
    RESTORE_PASSWORD_LINK,
    SET_PASSWORD_LINK,
    ROLE_MODELS_FORM_LINK,
    ROLE_MODELS_LIST_LINK,
    ROLE_MODELS_NEW_LINK,
    ADVENTURES_LIST_LINK,
    ADVENTURE_PAGE_LINK,
    ADVENTURE_CHAPTER_PAGE_LINK,
    ADVENTURE_PERSON_PAGE_LINK,
    ADVENTURE_PLACE_PAGE_LINK
} from './links';

import LoginPage from '../containers/login';
import DashboardPage from '../containers/dashboard';
import CharacterFormPage from '../containers/character/characterForm';
import CharacterNewPage from '../containers/character/characterNew';
import GroupForm from '../containers/groups/groupForm';
import GroupsList from '../containers/groups/groupsList';
import ModelsForm from '../containers/roleModels/modelForm';
import ModelsList from '../containers/roleModels/modelsList';
import ProfilePage from '../containers/profile';
import RegisterPage from '../containers/register';
import RestorePasswordPage from '../containers/restorePassword';
import SetPasswordPage from '../containers/setPassword';
import AdventurePage from '../containers/adventures/adventurePage';
import AdventuresListPage from '../containers/adventures/adventuresList';
import AdventureChapterPage from '../containers/adventures/adventureChapterPage';
import AdventurePersonPage from '../containers/adventures/adventurePersonPage';
import AdventurePlacePage from '../containers/adventures/adventurePlacePage';

const homePage = DASHBOARD_LINK;

const MainRoute = ({ user }) => (
    <Routes>
        <Route exact path="/" element={<Navigate to={homePage} />} />

        <Route exact path={DASHBOARD_LINK} element={<DashboardPage user={user}/>} />
        
        <Route exact path={ADVENTURE_PAGE_LINK} element={<AdventurePage user={user}/>} />
        <Route exact path={ADVENTURE_CHAPTER_PAGE_LINK} element={<AdventureChapterPage user={user}/>} />
        <Route exact path={ADVENTURE_PERSON_PAGE_LINK} element={<AdventurePersonPage user={user}/>} />
        <Route exact path={ADVENTURE_PLACE_PAGE_LINK} element={<AdventurePlacePage user={user}/>} />
        <Route exact path={ADVENTURES_LIST_LINK} element={<AdventuresListPage user={user}/>} />
        
        <Route exact path={CHARACTER_FORM_LINK} element={<CharacterFormPage user={user}/>} />
        <Route exact path={CHARACTER_NEW_LINK} element={<CharacterNewPage user={user}/>} />
        
        <Route exact path={GROUPS_FORM_LINK} element={<GroupForm user={user}/>} />
        <Route exact path={GROUPS_LIST_LINK} element={<GroupsList user={user}/>} />
        
        <Route exact path={ROLE_MODELS_FORM_LINK} element={<ModelsForm user={user}/>} />
        <Route exact path={ROLE_MODELS_NEW_LINK} element={<ModelsForm user={user}/>} />
        <Route exact path={ROLE_MODELS_LIST_LINK} element={<ModelsList user={user}/>} />
        
        <Route exact path={PROFILE_LINK} element={<ProfilePage user={user}/>} />
        
        <Route exact path={LOGIN_LINK} element={<LoginPage user={user}/>} />
        
        <Route exact path={REGISTER_LINK} element={<RegisterPage user={user}/>} />
        
        <Route exact path={RESTORE_PASSWORD_LINK} element={<RestorePasswordPage user={user}/>} />
        
        <Route exact path={SET_PASSWORD_LINK} element={<SetPasswordPage user={user}/>} />
        
        <Route exact path="*" element={<Navigate to={homePage} />} />
    </Routes>
);

export default MainRoute;