import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";

const AdventurePlaceDescriptionTab = ({
    place, 
    saveDescription, 
    disabled
}) => {
    const [description, setDescription] = useState(place.description || '');

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <MarkdownEditor
                        disabled={disabled}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => saveDescription(description)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AdventurePlaceDescriptionTab;