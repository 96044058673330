import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Grid, Header, Icon, Input, Tab } from "semantic-ui-react";
import { ADVENTURE_CHAPTER_INFO_HASH, ADVENTURE_CHAPTER_NOTES_HASH, ADVENTURE_CHAPTERS_HASH, ADVENTURE_PAGE_LINK } from "../../router/links";
import AdventureChapterInfoTab from "./tabs/adventureChapterInfoTab";
import AdventureChapterNotesTab from "./tabs/adventureChapterNotesTab";
import { loadAdventureChapterData, updateAdventureChapterField } from "../../api/adventureChapters";
import { loadAdventureNotesList } from "../../api/adventureNotes";

const AdventureChapterPage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    
    const [form, setForm] = useState({});
    const [notes, setNotes] = useState([]);
    const [name, setName] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isNotesLoading, setIsNotesLoading] = useState(false);

    const reloadNotes = (chapter) => {
        if (chapter.adventureId) {
            setIsNotesLoading(true);
            loadAdventureNotesList(chapter.adventureId, chapter.id, null, null)
                .then(result => {
                    setIsNotesLoading(false);
                    setNotes(result);
                })
                .catch(() => setIsNotesLoading(false));
        }
    };

    const getData = () => {
        setIsLoading(true);
        loadAdventureChapterData(id)
            .then(result => {
                setIsLoading(false);
                setForm(result);
                reloadNotes(result);
                setName(result.name || '');
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const saveField = (fieldName, value) => {
        setIsLoading(true);
        updateAdventureChapterField(id, fieldName, value)
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
    };

    const panes = [
        {
            tag: ADVENTURE_CHAPTER_INFO_HASH,
            render: () => (
                <AdventureChapterInfoTab
                    key={'adventure-chapter-info-' + (form.id || 'none')}
                    disabled={isLoading}
                    chapter={form} 
                    onSaveField={saveField}
                />
            )
        },
        {
            tag: ADVENTURE_CHAPTER_NOTES_HASH,
            render: () => (
                <AdventureChapterNotesTab
                    key={'adventure-chapter-notes-' + (form.id || 'none')}
                    disabled={isLoading || isNotesLoading}
                    adventureId={form.adventureId} 
                    chapterId={form.id} 
                    entries={notes}
                    onReload={() => reloadNotes(form)}
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
         } else {
            navigate(ADVENTURE_PAGE_LINK.replace(':id', form.adventureId) + ADVENTURE_CHAPTERS_HASH, { replace: true });
         }
    };

    return (
        <Container>
            <Grid divided='vertically'>
                <Grid.Row className='adventure-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Header size="medium" className="adventure-subpage-header">
                                <Icon name="globe" size="big" />
                            </Header>
                        </div>
                        <div className='header-title'>
                            <Header 
                                size="medium" 
                                className="adventure-subpage-header" 
                                style={{ marginLeft: 10 }}
                                onClick={() => navigate(ADVENTURE_PAGE_LINK.replace(':id', form.adventureId))}
                            >
                                {form.adventureName}
                            </Header>
                        </div>
                    </div>
                </Grid.Row>
                <Grid.Row className='adventure-header adventure-chapter-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Button circular basic icon='arrow left' onClick={goBack} />
                        </div>
                        <div className='header-title'>
                            <Input 
                                transparent 
                                size='big'
                                icon='list ol'
                                iconPosition='left'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={() => saveField('name', name)}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            to={ADVENTURE_CHAPTER_INFO_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_CHAPTER_INFO_HASH ? ' black' : '')}
                        >
                            <Icon name="info"/>
                        </Link>
                        <Link 
                            to={ADVENTURE_CHAPTER_NOTES_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_CHAPTER_NOTES_HASH ? ' black' : '')}
                        >
                            <Icon name="sticky note outline"/>
                        </Link>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: () => <></> }} panes={panes} activeIndex={activeTab} />
        </Container>
    );
};

export default AdventureChapterPage;