import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { loadAdventurePlaceData, loadAdventurePlaces, updateAdventurePlace } from "../../api/adventurePlace";
import { loadAdventureNotesList } from "../../api/adventureNotes";
import { loadAdventureChaptersList } from "../../api/adventureChapters";
import { loadAdventurePersonsList } from "../../api/adventurePersons";
import { Button, Container, Grid, Header, Icon, Input, Tab } from "semantic-ui-react";
import { ADVENTURE_PAGE_LINK, ADVENTURE_PLACE_DESCRIPTION_HASH, ADVENTURE_PLACE_MAP_HASH, ADVENTURE_PLACE_PAGE_LINK, ADVENTURE_PLACES_HASH } from "../../router/links";
import AdventurePlaceMapTab from "./tabs/adventurePlaceMapTab";
import AdventurePlaceDescriptionTab from "./tabs/adventurePlaceDescriptionTab";

const AdventurePlacePage = () => {
    const { id } = useParams();
    const { hash } = useLocation();
    const navigate = useNavigate();
    
    const [form, setForm] = useState({});
    const [name, setName] = useState('');
    const [chapters, setChapters] = useState([]);
    const [persons, setPersons] = useState([]);
    const [childPlaces, setChildPlaces] = useState([]);
    const [notes, setNotes] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const convertToOptions = (entry) => {
        return {
            key: entry.id,
            text: entry.name,
            value: entry.id
        };
    };

    const loadChapters = (adventureId) => {
        if (adventureId) {
            loadAdventureChaptersList(adventureId)
                .then(result => setChapters(result.map(convertToOptions)));
        }
    };

    const loadPersons = (adventureId) => {
        if (adventureId) {
            loadAdventurePersonsList(adventureId)
                .then(result => setPersons(result.map(convertToOptions)));
        }
    };

    const reloadChildPlaces = (place) => {
        loadAdventurePlaces(place.adventureId, place.id)
            .then(result => {
                setChildPlaces(result);
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const reloadNotes = (place) => {
        loadAdventureNotesList(place.adventureId, null, null, place.id)
            .then(result => setNotes(result));
    };

    const getData = () => {
        setIsLoading(true);
        loadAdventurePlaceData(id)
            .then(result => {
                setForm(result);
                setName(result.name || '');
                reloadChildPlaces(result);
                reloadNotes(result);
                loadChapters(result.adventureId);
                loadPersons(result.adventureId);
            })
            .catch(() => setIsLoading(false));
    }
    useEffect(getData, [id]);

    const onRename = () => {
        updateAdventurePlace(form.id, {
            ...form,
            name
        });
    };

    const saveDescription = (value) => {
        updateAdventurePlace(form.id, {
            ...form,
            description: value
        });
    };

    const panes = [
        {
            tag: ADVENTURE_PLACE_MAP_HASH,
            render: () => (
                <AdventurePlaceMapTab
                    key={'adventure-place-map-' + (form.id || 'none')}
                    disabled={isLoading}
                    place={form} 
                    chapters={chapters}
                    persons={persons}
                    notes={notes}
                    childPlaces={childPlaces}
                    reloadData={getData}
                    reloadNotes={() => reloadNotes(form)}
                    reloadChildPlaces={() => reloadChildPlaces(form)}
                />
            )
        },
        {
            tag: ADVENTURE_PLACE_DESCRIPTION_HASH,
            render: () => (
                <AdventurePlaceDescriptionTab
                    key={'adventure-place-description-' + (form.id || 'none')}
                    disabled={isLoading}
                    place={form} 
                    saveDescription={saveDescription}
                />
            )
        }
    ];

    const syncActiveTab = () => {
        if (hash) {
            panes.forEach((tab, index) => {
                if (tab.tag === hash) {
                    setActiveTab(index);
                }
            });
        } else {
            setActiveTab(0);
        }
    };
    useEffect(syncActiveTab, [hash]);

    const goBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
         } else {
            navigate(ADVENTURE_PAGE_LINK.replace(':id', form.adventureId) + ADVENTURE_PLACES_HASH, { replace: true });
         }
    };

    return (
        <Container>
            <Grid divided='vertically'>
                <Grid.Row className='adventure-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Header size="medium" className="adventure-subpage-header">
                                <Icon name="globe" size="big" />
                            </Header>
                        </div>
                        <div className='header-title'>
                            <Header 
                                size="medium" 
                                className="adventure-subpage-header" 
                                style={{ marginLeft: 10 }}
                                onClick={() => navigate(ADVENTURE_PAGE_LINK.replace(':id', form.adventureId))}
                            >
                                {form.adventureName}
                            </Header>
                        </div>
                    </div>
                    <div className='header-links'>
                        {form.parentPlaceId && form.parentPlaceName && (
                            <Link 
                                to={ADVENTURE_PLACE_PAGE_LINK.replace(':id', form.parentPlaceId)} 
                                className="ui basic circular icon labeled button header-up"
                            >
                                <Icon name="level up"/>
                                <span className="up-label">{form.parentPlaceName}</span>
                            </Link>
                        )}
                    </div>
                </Grid.Row>
                <Grid.Row className='adventure-header adventure-place-header'>
                    <div className='header-main'>
                        <div className='header-back'>
                            <Button circular basic icon='arrow left' onClick={goBack} />
                        </div>
                        <div className='header-title'>
                            <Input 
                                transparent 
                                size='big'
                                icon='map marker'
                                iconPosition='left'
                                className='title-input'
                                disabled={isLoading}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={onRename}
                            />
                        </div>
                    </div>
                    <div className='header-links'>
                        <Link 
                            to={ADVENTURE_PLACE_MAP_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PLACE_MAP_HASH ? ' black' : '')}
                        >
                            <Icon name="map outline"/>
                        </Link>
                        <Link 
                            to={ADVENTURE_PLACE_DESCRIPTION_HASH} 
                            className={'ui basic circular icon button' + (panes[activeTab].tag === ADVENTURE_PLACE_DESCRIPTION_HASH ? ' black' : '')}
                        >
                            <Icon name="edit outline"/>
                        </Link>
                    </div>
                </Grid.Row>
            </Grid>
            <Tab menu={{ as: () => <></> }} panes={panes} activeIndex={activeTab} />

        </Container>
    );
};

export default AdventurePlacePage;