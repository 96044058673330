import React from "react";
import NotesList from "../components/notesList";

const AdventureChapterNotesTab = ({
    adventureId,
    chapterId,
    disabled,
    entries,
    onReload
}) => {
    return (
        <NotesList
            adventureId={adventureId}
            chapterId={chapterId}
            disabled={disabled}
            entries={entries}
            onReload={onReload}
        />
    );
};

export default AdventureChapterNotesTab;