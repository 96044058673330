import React, { useEffect, useState } from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import ImageUploader from "../../../controls/imageUploader";

const ChangePlaceImageDialog = ({
    isOpen,
    disabled,
    title,
    onCancel,
    onSave
}) => {
    const [file, setFile] = useState(null);

    useEffect(() => {
        setFile(null);
    }, [isOpen]);

    const saveData = () => {
        const form = new FormData();
        form.append('file', file, file ? file.name : 'file');
        onSave(form);
    };

    return (
        <Modal size="tiny" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field>
                        <label>Новое изображение</label>
                        <ImageUploader 
                            disabled={disabled}
                            selectedFile={file}
                            onSelectFile={setFile}
                        />
                    </Form.Field>
                    <Message color='yellow'>
                        <p><b>Внимание!</b> Замена изображения может потребовать повторной корректировки размещения заметок и дочерних локаций.</p>
                    </Message>
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button 
                    onClick={onCancel}
                >
                    Отмена
                </Button>
                <Button 
                    positive 
                    disabled={disabled}
                    onClick={saveData}
                >
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default ChangePlaceImageDialog;