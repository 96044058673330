import React, { useState } from "react";
import { Grid } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";

const AdventureInfoTab = ({
    adventure, 
    onSaveField, 
    disabled
}) => {
    const [description, setDescription] = useState(adventure.description || '');

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <MarkdownEditor
                        disabled={disabled}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => onSaveField('description', description)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AdventureInfoTab;