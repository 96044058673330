import React from "react";
import { Icon, Menu, MenuItem } from 'semantic-ui-react';
import './customContextMenu.css';

const CustomContextMenu = ({
    items,
    isOpen,
    x,
    y,
    mapX,
    mapY,
    onItemClick
}) => {
    const itemClick = (e, item) => {
        onItemClick();
        item.onClick(mapX, mapY, e.pageX, e.pageY);
    };

    const menuItems = items && items.map((item, index) => (
        <MenuItem 
            key={'context-item-' + index}
            onClick={(e) => itemClick(e, item)}
        >
            {item.content ? item.content : (
                <>
                    {item.icon && <Icon name={item.icon} />}
                    {item.name}
                </>
            )}
        </MenuItem>
    ))

    return isOpen && (
        <Menu
            vertical
            className="context-menu"
            style={{
                left: x,
                top: y
            }}
        >
            {menuItems}
        </Menu>
    );
};

export default CustomContextMenu;