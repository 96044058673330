import React, { useState } from "react";
import { Form, FormField, Grid } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";

const AdventureChapterInfoTab = ({
    chapter, 
    onSaveField, 
    disabled
}) => {
    const [shortDescription, setShortDescription] = useState(chapter.shortDescription || '');
    const [description, setDescription] = useState(chapter.description || '');

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Form>
                        <FormField>
                            <label>Краткое описание</label>
                            <textarea
                                rows={2}
                                disabled={disabled}
                                value={shortDescription}
                                onChange={(e) => setShortDescription(e.target.value)}
                                onBlur={() => onSaveField('shortDescription', shortDescription)}
                            />
                        </FormField>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <MarkdownEditor
                        disabled={disabled}
                        value={description}
                        onChange={setDescription}
                        onBlur={() => onSaveField('description', description)}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default AdventureChapterInfoTab;