import React, { useEffect, useState } from "react";
import { Form, Button, Dropdown, Modal, FormGroup, Icon, Input, Grid, FormField } from "semantic-ui-react";
import MarkdownEditor from "../../../controls/markdownEditor";
import { getAllIcons } from '../../../api/icons';

const EditNoteDialog = ({
    isOpen,
    entry,
    chapters,
    persons,
    disabled,
    onCancel,
    onSave
}) => {
    const [chapter, setChapter] = useState(null);
    const [person, setPerson] = useState(null);
    const [icon, setIcon] = useState('sticky note outline');
    const [name, setName] = useState('');
    const [text, setText] = useState('');
    const [icons, setIcons] = useState([]);
    
    useEffect(() => {
        setIcons(getAllIcons().map((key) => ({
            key,
            value: key,
            text: <Icon name={key}/>,
            searchText: key
        })));
    }, []);

    useEffect(() => {
        setText(entry ? entry.text : '');
        setName(entry ? entry.name : '');
        setIcon(entry ? entry.icon : 'sticky note outline');
        setChapter(entry && entry.chapter ? entry.chapter.id : null);
        setPerson(entry && entry.person ? entry.person.id : null);
    }, [isOpen]);

    const save = () =>
        onSave({
            ...entry,
            chapter: chapter ? { id: chapter } : null,
            person: person ? { id: person } : null,
            icon,
            name,
            text
        });

    const title = entry && entry.id ? 'Редактирование заметки' : 'Создание новой заметки';

    const escapeRegex = (string) => string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

    return (
        <Modal size="small" open={isOpen}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                <Form>
                    <FormGroup inline>
                        <FormField width={2}>
                            <Dropdown
                                selection
                                options={icons}
                                value={icon}
                                search={(options, query) => {
                                    const re = new RegExp(escapeRegex(query), 'i');
                                    return options.filter((opt) => re.test(opt.searchText));
                                }}
                                disabled={disabled}
                                onChange={(e, { value }) => setIcon(value)}
                                className="icon-dropdown"
                            />
                        </FormField>
                        <FormField width={14}>
                            <label>Заголовок</label>
                            <Input 
                                value={name} 
                                disabled={disabled}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormField>
                    </FormGroup>
                    <FormGroup inline>
                        <FormField width={8}>
                            <label>Глава</label>
                            <Dropdown
                                search
                                selection
                                options={[{},].concat(chapters)}
                                value={chapter}
                                disabled={disabled}
                                onChange={(e, { value }) => setChapter(value)}
                            />
                        </FormField>
                        <FormField width={8}>
                            <label>Персонаж</label>
                            <Dropdown
                                search
                                selection
                                options={[{},].concat(persons)}
                                value={person}
                                disabled={disabled}
                                onChange={(e, { value }) => setPerson(value)}
                            />
                        </FormField>
                    </FormGroup>
                </Form>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <MarkdownEditor
                                value={text}
                                disabled={disabled}
                                onChange={setText}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCancel} disabled={disabled}>
                    Отмена
                </Button>
                <Button positive onClick={save} disabled={disabled}>
                    Сохранить
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default EditNoteDialog;