import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader, ItemImage } from "semantic-ui-react";
import CreateNamedEntryDialog from "../components/createNamedEntryDialog";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import { ADVENTURE_CHAPTER_PAGE_LINK } from "../../../router/links";
import { createAdventureChapter, deleteAdventureChapter, reorderAdventureChapter } from "../../../api/adventureChapters";

const AdventureChaptersTab = ({
    adventureId,
    chapters,
    disabled,
    onReload
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);

    const onAddNewChapter = (name) => {
        setIsNewDialogOpen(false);
        if (adventureId) {
            setIsLoading(true);
            createAdventureChapter(adventureId, { name })
                .then(() => {
                    onReload();
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить главу '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventureChapter(id)
            .then(() => {
                onReload();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const onDragStart = (ev, id) => ev.dataTransfer.setData("id", id);
    const onDragOver = ev => ev.preventDefault();

    const onDrop = (ev, newIndex) => {
        let id = ev.dataTransfer.getData("id");
        setIsLoading(true);
        reorderAdventureChapter(id, newIndex)
            .then(() => {
                onReload();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = chapters.map((entry, ind) => (
        <Item 
            key={'person-' + ind}
            draggable
            className="draggable"
            onDragStart={e => onDragStart(e, entry.id)}
            onDragOver={e => onDragOver(e)}
            onDrop={e => onDrop(e, ind + 1)}
        >
            <ItemImage style={{ width: 25, cursor: 'pointer' }}>
                <Icon name="bars" size='large' />
            </ItemImage>
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_CHAPTER_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                <ItemDescription>{entry.shortDescription}</ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="medium" className="inline-title">
                        Главы
                    </Header>
                    <Button
                        circular
                        positive
                        icon='plus'
                        size="tiny"
                        className="inline-title"
                        disabled={isLoading || disabled}
                        onClick={() => setIsNewDialogOpen(true)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание новой главы'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewChapter}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление главы'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

export default AdventureChaptersTab;