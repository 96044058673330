import React, { useState } from "react";
import { Button, Grid, Header, Icon, Item, ItemContent, ItemDescription, ItemGroup, ItemHeader } from "semantic-ui-react";
import CreateNamedEntryDialog from "../components/createNamedEntryDialog";
import ConfirmationDialog from "../../../modals/confirmationDialog";
import { Link } from "react-router-dom";
import { ADVENTURE_PERSON_PAGE_LINK } from "../../../router/links";
import { createAdventurePerson, deleteAdventurePerson } from "../../../api/adventurePersons";

const AdventurePersonsTab = ({
    adventureId,
    persons,
    disabled,
    onReload
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isNewDialogOpen, setIsNewDialogOpen] = useState(false);
    const [confirmationDialog, setConfirmationDialog] = useState({ isOpen: false });

    const onAddNewPerson = (name) => {
        setIsNewDialogOpen(false);
        if (adventureId) {
            setIsLoading(true);
            createAdventurePerson(adventureId, { name })
                .then(() => {
                    onReload();
                    setIsLoading(false);
                })
                .catch(() => setIsLoading(false));
        }
    };

    const handleDelete = (id, name) => {
        setConfirmationDialog({
            message: `Вы действительно хотите удалить персонажа '${name}'?`,
            isOpen: true,
            id
        });
    };

    const cancelDelete = () => {
        setConfirmationDialog({
            isOpen: false
        });
    };

    const confirmDelete = () => {
        const id = confirmationDialog.id;
        cancelDelete();
        setIsLoading(true);
        deleteAdventurePerson(id)
            .then(() => {
                onReload();
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    const items = persons.map((entry, ind) => (
        <Item key={'person-' + ind}>
            <Icon name="user circle" size="huge" />
            <ItemContent>
                <ItemHeader>
                    <Link to={ADVENTURE_PERSON_PAGE_LINK.replace(':id', entry.id)}>
                        {entry.name}
                    </Link>
                    <Icon 
                        className="remove-button"
                        name="trash alternate" 
                        onClick={() => handleDelete(entry.id, entry.name)}
                    />
                </ItemHeader>
                <ItemDescription>{entry.shortDescription}</ItemDescription>
            </ItemContent>
        </Item>
    ));

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="medium" className="inline-title">
                        Персонажи
                    </Header>
                    <Button
                        circular
                        positive
                        icon='plus'
                        size="tiny"
                        className="inline-title"
                        disabled={isLoading || disabled}
                        onClick={() => setIsNewDialogOpen(true)}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns='equal'>
                <Grid.Column>
                    <ItemGroup divided>{items}</ItemGroup>
                </Grid.Column>
            </Grid.Row>
            <CreateNamedEntryDialog
                isOpen={isNewDialogOpen}
                title='Создание нового персонажа'
                onCancel={() => setIsNewDialogOpen(false)}
                onSave={onAddNewPerson}
            />
            <ConfirmationDialog
                isOpen={confirmationDialog.isOpen}
                title='Удаление персонажа'
                message={confirmationDialog.message}
                onCancel={cancelDelete}
                onSave={confirmDelete}
            />
        </Grid>
    );
};

export default AdventurePersonsTab;