import './mapContainer.css';
import React, { useLayoutEffect, useRef, useState } from "react";
import CustomContextMenu from './customContextMenu';
import { Icon } from 'semantic-ui-react';

const MapContainer = ({
    mapUrl,
    points,
    contextMenuItems
}) => {
    const mapTileId = "map-tile";

    const containerRef = useRef();
    const imgRef = useRef();

    const [viewport, setViewport] = useState({
        x: 0,
        y: 0,
        scale: 1,
        height: 1
    });

    const [contextMenu, setContentMenu] = useState({ isOpen: false });
    const [pointContextMenu, setPointContextMenu] = useState({ isOpen: false });
    const [movingPoint, setMovingPoint] = useState(null);

    const closeContextMenus = () => {
        setContentMenu({ isOpen: false });
        setPointContextMenu({ isOpen: false });
    }

    useLayoutEffect(() => {
        const syncWindowSize = () => {
            containerRef.current && setViewport({
                ...viewport,
                height: containerRef.current.offsetParent.offsetHeight - containerRef.current.offsetTop - 10
            });
        };

        window.addEventListener('resize', syncWindowSize);
        syncWindowSize();

        return () => {
            window.removeEventListener('resize', syncWindowSize);
        };
    }, []);

    const scale = (v) => Math.floor(v * viewport.scale);
    const unScale = (v) => Math.floor(v / viewport.scale);

    const getPointPosition = (point) => ({
        x: scale(point.x) - viewport.x,
        y: scale(point.y) - viewport.y
    });

    const getRealPosition = (point) => ({
        x: unScale(point.x + viewport.x),
        y: unScale(point.y + viewport.y)
    });

    const fixScale = (newScale) => Math.max(Math.min(newScale, 100), 0.1);

    const fixX = (newX, actualWidth) => 
        Math.max(
            Math.min(
                newX, 
                Math.max(actualWidth - containerRef.current.clientWidth, 0)
            ), 
            Math.min(actualWidth - containerRef.current.clientWidth, 0)
        );

    const fixY = (newY, actualHeight) => 
        Math.max(
            Math.min(
                newY, 
                Math.max(actualHeight - containerRef.current.clientHeight, 0)
            ), 
            Math.min(actualHeight - containerRef.current.clientHeight, 0)
        );

    const onWheelImage = (e) => {
        const { deltaY, offsetX, offsetY } = e.nativeEvent;
        const { id, offsetWidth, offsetHeight } = e.target;
        if (id === mapTileId) {
            const newScale = fixScale(viewport.scale * (1 - deltaY / 1000));

            const scaleFactor = newScale / viewport.scale;
            const newX = fixX(viewport.x + Math.floor(offsetX * (scaleFactor - 1)), Math.floor(offsetWidth * scaleFactor));
            const newY = fixY(viewport.y + Math.floor(offsetY * (scaleFactor - 1)), Math.floor(offsetHeight * scaleFactor));

            setViewport({
                ...viewport,
                x: newX,
                y: newY,
                scale: newScale
            });
        }
    };

    const onMouseMoveImage = (e) => {
        const { movementX, movementY, buttons } = e;
        const { id, offsetWidth, offsetHeight } = e.target;
        const { offsetX, offsetY } = e.nativeEvent;

        if (id === mapTileId && buttons === 1) {
            const newX = fixX(viewport.x - movementX, offsetWidth);
            const newY = fixY(viewport.y - movementY, offsetHeight);

            setViewport({
                ...viewport,
                x: newX,
                y: newY
            });
        } else if (id === mapTileId && movingPoint) {
            const viewPoint = { x: offsetX - viewport.x, y: offsetY - viewport.y };
            const realPoint = getRealPosition(viewPoint);
            setMovingPoint({
                ...movingPoint,
                x: realPoint.x,
                y: realPoint.y,
                viewportX: viewPoint.x,
                viewportY: viewPoint.y
            });
        }
    };

    const onMovingPointMove = (e) => {
        if (movingPoint) {
            const { offsetX, offsetY } = e.nativeEvent;
            const viewportX = movingPoint.viewportX + offsetX - 14;
            const viewportY = movingPoint.viewportY + offsetY - 14;
            const realPoint = getRealPosition({ x: viewportX, y: viewportY });
            setMovingPoint({
                ...movingPoint,
                x: realPoint.x,
                y: realPoint.y,
                viewportX: viewportX,
                viewportY: viewportY
            });
        }
    };

    const onImageContextMenu = (e) => {
        e.preventDefault();

        const { offsetX, offsetY } = e.nativeEvent;
        const viewPoint = { x: offsetX - viewport.x, y: offsetY - viewport.y };
        const realPoint = getRealPosition(viewPoint);

        setContentMenu({
            isOpen: true,
            x: offsetX - viewport.x,
            y: offsetY - viewport.y,
            mapX: realPoint.x,
            mapY: realPoint.y
        })
    };

    const onPointClick = (point) => {
        const position = getPointPosition(point);
        setPointContextMenu({
            isOpen: true,
            x: position.x + 7,
            y: position.y - 7,
            mapX: point.x,
            mapY: point.y,
            items: point.contextItems.concat([
                {
                    icon: 'move',
                    name: 'Переместить',
                    onClick: (x, y, pageX, pageY) => {
                        closeContextMenus();
                        setMovingPoint({
                            ...point,
                            viewportX: pageX - containerRef.current.offsetLeft,
                            viewportY: pageY - containerRef.current.offsetTop
                        });
                    }
                },
            ])
        });
    };

    const onMovingPointClick = () => {
        if (movingPoint) {
            movingPoint.onMove(movingPoint.x, movingPoint.y);
            setMovingPoint(null);
        }
    };

    return (
        <div 
            ref={containerRef}
            className='map-container'
            onWheel={onWheelImage}
            onMouseMove={onMouseMoveImage}
            style={{
                height: viewport.height
            }}
        >
            <img 
                ref={imgRef}
                id={mapTileId}
                src={mapUrl}
                alt=""
                draggable={false}
                onContextMenu={onImageContextMenu}
                onClick={closeContextMenus}
                style={{
                    left: -viewport.x,
                    top: -viewport.y,
                    width: imgRef.current && imgRef.current.naturalWidth > 0 ? scale(imgRef.current.naturalWidth) : 'auto'
                }}
            />

            {movingPoint ? (
                <Icon
                    circular
                    key="map-moving-point"
                    name={movingPoint.icon}
                    className='map-point map-moving-point'
                    onMouseMove={onMovingPointMove}
                    onClick={onMovingPointClick}
                    style={{
                        left: movingPoint.viewportX - 14,
                        top: movingPoint.viewportY - 14
                    }}
                />
            ) : points && points.map((point, index) => {
                const position = getPointPosition(point);
                return (
                    <Icon
                        circular
                        key={"map-point" + index}
                        name={point.icon}
                        title={point.title}
                        className='map-point'
                        onClick={() => onPointClick(point)}
                        style={{
                            left: position.x - 14,
                            top: position.y - 14,
                        }}
                    />
                );
            })}

            <CustomContextMenu 
                key="map-image-context-menu"
                items={contextMenuItems}
                isOpen={contextMenu.isOpen}
                x={contextMenu.x}
                y={contextMenu.y}
                mapX={contextMenu.mapX}
                mapY={contextMenu.mapY}
                onItemClick={closeContextMenus}
            />

            <CustomContextMenu
                key="map-point-context-menu"
                items={pointContextMenu.items}
                isOpen={pointContextMenu.isOpen}
                x={pointContextMenu.x}
                y={pointContextMenu.y}
                mapX={pointContextMenu.mapX}
                mapY={pointContextMenu.mapY}
                onItemClick={closeContextMenus}
            />
        </div>
    );
};

export default MapContainer;